import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, Input, Button } from '@material-ui/core'
import {
  CollectionsBookmark,
  ColorLens,
  Public,
  Business,
  Eco,
  Computer,
  LocalHospital,
  Build,
  Work,
} from '@material-ui/icons'

import CardCordenation from '../../components/CardCordenation'
import bete from '../../images/bete.png'
import zaira from '../../images/zaira.jpeg'
import luzia from '../../images/luzia.png'
import almir from '../../images/almir.png'
import adenilda from '../../images/adenilda.jpg'
import alaide from '../../images/alaide.jpg'
import valmir from '../../images/valmir.png'
import tereza from '../../images/tereza.jpeg'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CourseWrapper from '../../components/CourseWrapper'

import { PageTitle } from '../../components/PageTitle'
import {
  SectionTitle,
  SearchBar,
} from '../../styles/pages/presencial'

export default function GraduacaoEadPage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('');
  const [inputv, setInputv] = useState('');
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.normalize("NFC").toLowerCase())
    )
    setSearchResults(posts)
  }, [query.toLowerCase()])


  return (
    <Layout>
      <SEO title="Graduação EaD" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Graduação EaD</PageTitle>
        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            value={inputv}
            onChange={e => setInputv(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
          <Button

            onClick={(event) => {
              setQuery(inputv)
              document.body.scrollTop = 800;
            }}>
            Pesquisar
          </Button>
        </SearchBar>
        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          Na modalidade a distância, as atividades virtuais (videoaula, e-book,
          chats, fóruns, etc.) ficam a todo tempo disponíveis aos alunos mediante
          acesso ao AVA* – Ambiente Virtual de Aprendizagem da Faculdade Jardins.
          As atividades presenciais previstas ocorrem em encontros previamente marcados
          semestralmente.
          <br /> <br />
          <span style={{ fontSize: 15, color: '#555' }}>
            *AVA – Ambiente Virtual de Aprendizagem da Faculdade Jardins é uma
            Plataforma de Aprendizagem on-line completa para otimizar a formação
            de nossos alunos, dispondo, inclusive, de videoaula, e-books, biblioteca
            virtual, periódicos virtuais, materiais de aula dos docentes e tutores,
            slides, artigos, textos, hipertextos, links, chats, fóruns, exercícios, avaliações, etc.
          </span>
        </p>
      </Container>

      <Container style={{ marginTop: 60, maxWidth: 1724 }}>
        <SectionTitle>Cursos de Graduação</SectionTitle>
        <div>
          {searchResults.map(({ node }) => (
            <CourseWrapper
              name={node.frontmatter.name}
              duration={node.frontmatter.duration}
              price={node.frontmatter.price}
              parcel={node.frontmatter.parcelas}
              subscribe={node.frontmatter.subscribe}
              link={node.fields.slug}
              key={node.frontmatter.price}
            />
          ))}
        </div>
      </Container>

      <Container style={{ marginTop: 40 }}>
        <h4 style={{ textAlign: 'center' }}>CONHEÇA ALGUNS DE NOSSOS COORDENADORES</h4>
        <Grid container spacing={2} direction="row">
          {/* <Grid xs={12} item sm={12} md={6} lg={6} spacing={2}> */}
          <CardCordenation
            title="Professora Elizabete Farias Lima Silva"
            description={<p>Enfermeira. Doutoranda em educação pela UFS.<br></br> Mestra em Enfermagem pela EEAN/ UFRJ. <br></br> Servidora do HU/ Sergipe. Coordenadora dos Cursos de graduação e pós graduação da Faculdade Jardins.</p>}
            image={bete}
          />
          <CardCordenation
            title="Profa. Dra. Záira Moura"
            description={<p>Doutora em Ciências da Saúde, Enfermeira da Secretaria de Saúde do Estado de Sergipe.<br></br>http://lattes.cnpq.br/6177265138676197</p>}
            image={zaira}
          />
          <CardCordenation
            title="Prof. Dr. Valmir Martins"
            description="Pós Doutor em Democracia e Direitos Humanos, Mestre e Doutor na área de Administração. Profissional com experiência de 30 anos como Oficial Militar, gestor de empresas educacionais, professor e consultor em Direito Educacional, empresário e pesquisador.
            CV: http://lattes.cnpq.br/5995246863621264"
            image={valmir}
          />
          <CardCordenation
            title="Profa. Dra. Alaíde Barbosa"
            description="Pós Doutora em Engenharia pela Poli USP, Engenheira, Administradora, Analista de Computação e Química. Profissional com experiência como Diretora Executiva em Empresas Multinacionais, Empresária e Educadora.
            CV: http://lattes.cnpq.br/6685382103923859"
            image={alaide}
          />
          <CardCordenation
            title="Profa. Ma. Adenilda Couto"
            description="Mestra em Planejamento Territorial e Desenvolvimento Social, Assistente Social. Administradora e Licenciada em Pedagogia, profissional com vasta experiência em gestão, coordenação na área acadêmica.
            CV: http://lattes.cnpq.br/600223525395733"
            image={adenilda}
          />
          <CardCordenation
            title="Prof. Me. Almir Martins"
            description="Mestre em Planejamento Territorial e Desenvolvimento Social. Administrador com experiência acadêmica e em grandes empresas.
            CV: http://lattes.cnpq.br/0291039112670467"
            image={almir}
          />
          <CardCordenation
            title="Profa. Ma. Luzia Barreto"
            description="Mestra, com graduações em Engenharia, Licenciatura em Ciências Biológicas e Pedagogia. Profissional com experiência em EAD, consultoria, pesquisa e educadora.
            CV: http://lattes.cnpq.br/3932085275496056"
            image={luzia}
          />
          <CardCordenation
            title="Dra. Ma. Tereza Cristina Martins"
            description="Odontóloga. Mestra em Odontologia pelo CPO São Leopoldo Mandic (Unicamp), Especialista em Ortodontia pelo CPOSLM, Especialista em Odontologia do Trabalho pelo CPOSLM. Ortodontista da Clínica Ortopronto e do Centro de Ortodontia da Odonto System."
            image={tereza}
          />

          {/* </Grid> */}
        </Grid>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { category: { eq: "graduacao ead" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            price
            duration
            parcelas
            subscribe
          }
        }
      }
    }
  }
`
